import * as React from 'react'
import Seo from '../components/seo'
import Layout from '../components/layout'
import { graphql } from "gatsby"
import WhoWeAre from '../components/WhoWeAre'
import EventListing from '../components/EventListing'
import VideoBanner from "../components/VideoBanner"

export default function Event(pageData) {
  const data = pageData?.data

  let seo = pageData?.pageContext?.node?.seo
  let translatedURI = pageData?.pageContext?.node?.translations[0]?.uri

    return (
        <>
        <Seo data={seo}/>
        <Layout translatedURI={translatedURI}>

          <div className="events-who-we-are">
            <WhoWeAre
              title={data?.wpPage?.pageSubtitle?.subtitle}
              name={data?.wpPage?.title}
              description={data?.wpPage?.pageDescription?.pageDescription}
            />
          </div>
          
          <VideoBanner data={data?.wpPage?.eventFields?.eventVideo} />
          <EventListing />
        
        </Layout>
        </>
    )
}

export const pageQuery = (graphql` query eventData($databaseId:Int) {
    wpPage(databaseId: {eq: $databaseId}) {
      id
      title
      pageSubtitle {
        fieldGroupName
        subtitle
      }
      pageDescription {
        fieldGroupName
        pageDescription
      }
      eventFields{
        eventVideo {
          eventVideo {
          localFile {
            publicURL
            }
          }
          videoLink
        }
      }
    }
  }`);
  
import React, { useState, useMemo, useEffect, useRef, useContext } from "react"
import "./eventlisting.scss"
import "../LatestUpdates/pagination.scss"
import { createMarkup, string_translation } from "../../../utils"
import { Link, useStaticQuery, graphql } from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

const FILTER_KEYS_TO_RENDER = ["eventTypes", "eventActivities"]

export default function EventListing(props) {
  const dbdata = useStaticQuery(graphql`
    {
      events_ar: allWpEvent(filter: { language: { slug: { eq: "ar" }}, status: {eq: "publish"} }) {
        nodes {
          id
          title
          uri
          content
          tags {
            nodes {
              name
            }
          }
          previewImage {
            previewImage {
              localFile {
                publicURL
              }
            }
          }
          seo {
            metaDesc
            title
            opengraphImage {
              localFile {
                publicURL
              }
            }
            canonical
          }
          eventActivities {
            nodes {
              name
            }
          }
          eventTypes {
            nodes {
              name
            }
          }
          eventOptions {
            messageFromTheBoard {
              image {
                localFile {
                  publicURL
                }
                author {
                  node {
                    name
                  }
                }
              }
              message
              authorName
              subtitle
            }
            color
            buttonName
            eventDateFrom
            eventDateTo
          }
          date(formatString: "D. MM. YYYY")
          uri
          excerpt
        }
      }
      events_en: allWpEvent(filter: { language: { slug: { eq: "en" }}, status: {eq: "publish"} }) {
        nodes {
          id
          title
          uri
          content
          tags {
            nodes {
              name
            }
          }
          previewImage {
            previewImage {
              localFile {
                publicURL
              }
            }
          }
          seo {
            metaDesc
            title
            opengraphImage {
              localFile {
                publicURL
              }
            }
            canonical
          }
          eventActivities {
            nodes {
              name
            }
          }
          eventTypes {
            nodes {
              name
            }
          }
          eventOptions {
            messageFromTheBoard {
              image {
                localFile {
                  publicURL
                }
                author {
                  node {
                    name
                  }
                }
              }
              message
              authorName
              subtitle
            }
            color
            buttonName
            eventDateFrom
            eventDateTo
          }
          date(formatString: "D. MM. YYYY")
          uri
          excerpt
        }
      }
      stringTranslations {
        year_ar: translateString(language: AR, string: "YEAR")
        year_en: translateString(language: EN, string: "YEAR")
        month_ar: translateString(language: AR, string: "MONTH")
        month_en: translateString(language: EN, string: "MONTH")
        type_ar: translateString(language: AR, string: "TYPE")
        type_en: translateString(language: EN, string: "TYPE")
        activity_ar: translateString(language: AR, string: "ACTIVITY")
        activity_en: translateString(language: EN, string: "ACTIVITY")
        search_ar: translateString(language: AR, string: "SEARCH")
        search_en: translateString(language: EN, string: "SEARCH")
        advanced_ar: translateString(language: AR, string: "ADVANCED")
        advanced_en: translateString(language: EN, string: "ADVANCED")
        clear_en: translateString(language: EN, string: "CLEAR")
        clear_ar: translateString(language: AR, string: "CLEAR")
        to_en: translateString(language: EN, string: "TO")
        to_ar: translateString(language: AR, string: "TO")
        no_ar: translateString(language: AR, string: "NO EVENTS")
        no_en: translateString(language: EN, string: "NO EVENTS")

      }
    }
  `)
  const { locale, originalUrl } = useContext(TranslateUrlsContext)

  const [todos, setTodos] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [filterCompleted, setFilterCompleted] = useState([])
  const [AfilterCompleted, setAFilterCompleted] = useState([])
  const [TfilterCompleted, setTFilterCompleted] = useState([])
  const [MfilterCompleted, setMFilterCompleted] = useState([])


  const [active, setactive] = useState(false)
  const [Mactive, setMactive] = useState(false)
  const [Tactive, setTactive] = useState(false)
  const [Aactive, setAactive] = useState(false)

  const [hasactivefilter, sethasactivefilter] = useState(null)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalTodos, setTotalTodos] = useState(0)
  const todosPerPage = 3

  let newtodos = []

  const parseYear = datestring => {
    if(datestring != null){
      const [day, month, year] = datestring.split('/');
      return year
    }
  }

  const parseMonth = datestring => {
    if(datestring != null){
      const [day, month, year] = datestring.split('/');
      const date = new Date(+year, +month, +day);
      return month
    }
  }
  let newevents

  if (locale == "ar") {
    newevents = dbdata.events_ar.nodes
  } else {
    newevents = dbdata.events_en.nodes
  }  
  
  newevents.map((item, index) => {
    return newtodos.push(item)
  })

  useEffect(() => {
    setTodos(newevents)
  }, [])

  const pageNumbers = []



  for (let i = 1; i <= Math.ceil(totalTodos / todosPerPage); i++) {
    pageNumbers.push(i)
  }

  const todosData = useMemo(() => {
    let computedTodos = todos

    if (TfilterCompleted.length != 0) {
      computedTodos = computedTodos.filter(
        todo => TfilterCompleted.includes(todo.eventTypes?.nodes[0]?.name)
      )
    }
    if (AfilterCompleted.length != 0) {
      computedTodos = computedTodos.filter(
        todo => AfilterCompleted.includes(todo.eventActivities?.nodes[0]?.name)
      )
    }
    if (MfilterCompleted.length != 0) {
      computedTodos = computedTodos.filter(
        todo => MfilterCompleted.includes(parseMonth(todo?.eventOptions?.eventDateFrom))
      )
    }
    if (filterCompleted.length != 0) {
      computedTodos = computedTodos.filter(
        todo => filterCompleted.includes(parseYear(todo?.eventOptions?.eventDateFrom))
      )
    }
    setTotalTodos(computedTodos.length)

    //Current Page slice
    return computedTodos.slice(
      (currentPage - 1) * todosPerPage,
      (currentPage - 1) * todosPerPage + todosPerPage
    )
  }, [todos, currentPage, searchTerm, filterCompleted, TfilterCompleted, AfilterCompleted, MfilterCompleted])

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber)

  const onNext = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1)
    }
  }
  const onPrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }
  const resetFilter = () => {
    setFilterCompleted([])
    setAFilterCompleted([])
    setMFilterCompleted([])
    setTFilterCompleted([])
    setCurrentPage(1)
  }

  const handleFilterSelection = (item, key) =>{
  if(key == "TFilter"){
    if(!TfilterCompleted.includes(item)){
      setTFilterCompleted(prev =>[...prev, item])
    }else{
      const TFiltered = TfilterCompleted.filter(element => element !== item)
      setTFilterCompleted([...TFiltered])
    }
  }else if(key == "AFilter"){
    if(!AfilterCompleted.includes(item)){
      setAFilterCompleted(prev =>[...prev, item])
    }else{
      const AFiltered = AfilterCompleted.filter(element => element !== item)
      setAFilterCompleted([...AFiltered])
    }
  }else if(key =="MFilter"){
    if(!MfilterCompleted.includes(item)){
      setMFilterCompleted(prev =>[...prev, item])
    }else{
      const MFiltered = MfilterCompleted.filter(element => element !== item)
      setMFilterCompleted([...MFiltered])
    }
  }else if(key == "Filter"){
    if(!filterCompleted.includes(item)){
      setFilterCompleted(prev =>[...prev, item])
    }else{
      const Filtered = filterCompleted.filter(element => element !== item)
      setFilterCompleted([...Filtered])
    }
  }
}

  const toggleActiveBtn = e => {
    if (hasactivefilter !== e?.currentTarget) {
      const btnsTip = document.querySelectorAll(".anchor")
      btnsTip.forEach(btnTip => {
        e.currentTarget.classList.remove("selected")
      })
      e.currentTarget.classList.add("selected")
      sethasactivefilter(e.currentTarget)
    } else {
      const filterTarget = e?.currentTarget
      sethasactivefilter(filterTarget)
      filterTarget.classList.toggle("selected")
    }
  }
  const handleclick = () => {
    // setSearchTerm(textInput.current.value)
    setAFilterCompleted([])
    setMFilterCompleted([])
    setTFilterCompleted([])
    setFilterCompleted([])
    setCurrentPage(1)
    const btnsTip = document.querySelectorAll(".anchor")
    btnsTip.forEach(btnTip => {
      btnTip.classList.remove("selected")
    })  }
  let pagination = []
  if (typeof document !== "undefined") {
    pagination = document.querySelectorAll(".page-item")
  }
  let activepagination = null
  pagination.forEach(btnTip => {
    btnTip.addEventListener("click", e => {
      e.currentTarget.classList.add("active")
      if (activepagination !== null && activepagination !== e.currentTarget) {
        activepagination.classList.remove("active")
      }
      activepagination = e.currentTarget
    })
  })
  let btnsTip = []
  if (typeof document !== "undefined") {
    btnsTip = document.querySelectorAll(".anchor")
  }

  let pag = totalTodos > 3 ? true : false

  let years = []
  let months = []
  let types = []
  let activities = []


  todos.map(item => {
    if(item?.eventOptions?.eventDateFrom != null){
      years.push(parseYear(item?.eventOptions?.eventDateFrom))
      return true
    }
  })
  const YwithoutDuplicates = [...new Set(years)]

  todos.map(item => {
    if(item?.eventOptions?.eventDateFrom != null){
      return months.push(parseMonth(item?.eventOptions?.eventDateFrom))
    }
  })
  const MwithoutDuplicates = [...new Set(months)]

  todos.map(item => {
    if(item?.eventTypes?.nodes[0]?.name != null){
      return types.push(item?.eventTypes?.nodes[0]?.name)
    }
  })
  const TwithoutDuplicates = [...new Set(types)]

  todos.map(item => {
    if(item?.eventActivities?.nodes[0]?.name != null){
      return activities.push(item?.eventActivities?.nodes[0]?.name)
    }
  })
  const AwithoutDuplicates = [...new Set(activities)]

  const filterRef = useRef(null)
  useEffect(() => {
    document.addEventListener("click", handleOutsideClicks);
      return () => {
        document.removeEventListener("click", handleOutsideClicks);
    };

}, [Mactive, Aactive, Tactive, active]);

const handleOutsideClicks =(event)=>{
  if(Mactive || Aactive || Tactive || active && filterRef.current && !filterRef.current.contains(event.target)){
      setMactive(false);
      setAactive(false);
      setactive(false);
      setTactive(false);
   };
};

const scrollToTop = () => {
  window.scrollTo({
    top: 900,
    behavior: 'smooth'
  });
};

  return (
    <>
    <div className="events-listing">
      <section className="last-happenings">
        <div className="search-bar-wrapper">
          <div className="search-bar">
            <div className="right-col">
              <div className="btns-wrapper" ref={filterRef}>
                <div
                  className={`advanced-btn ${active ? "active" : ""}`}
                  onClick={() => {
                    setactive(!active)
                    setAactive(false)
                    setMactive(false)
                    setTactive(false)
                  }}
                >
                  <div className="text">
                  {string_translation(dbdata.stringTranslations, "year", locale)}
                  </div>
                  <div className="drop-down-arrow">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12.7891" r="12" fill="#3D3935" />
                      <path
                        d="M11.4999 15.7891L11.88 15.4407L16 11.7245L15.2398 10.7891L11.5 14.1633L7.76023 10.7891L7 11.7245L11.12 15.4407L11.4999 15.7891Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.5"
                      />
                    </svg>
                  </div>
                  <div
                    className={`advanced-search-wrapper ${
                      active ? "active" : ""
                    }`}
                  >
                    <div className="col-group">
                      <div className="col-dt-11">
                        <div className="selections">
                          {YwithoutDuplicates.map(item => {
                            const isSelected = filterCompleted.includes(item)
                            const classtopass = isSelected? "selected" : ""
                            return (
                              <a
                                href="javascript:void(0)"
                                className={`anchor ${classtopass && classtopass}`}
                                onClick={e => {
                                  handleFilterSelection(item, "Filter")
                                  setCurrentPage(1)
                                  toggleActiveBtn(e)
                                  setactive(false)
                                }}
                              >
                                {item}
                              </a>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`advanced-btn ${Mactive ? "active" : ""}`}
                  onClick={() => {
                    setMactive(!Mactive)
                    setAactive(false)
                    setactive(false)
                    setTactive(false)
                  }}
                >
                  <div className="text">
                  {string_translation(dbdata.stringTranslations, "month", locale)}
                  </div>
                  <div className="drop-down-arrow">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12.7891" r="12" fill="#3D3935" />
                      <path
                        d="M11.4999 15.7891L11.88 15.4407L16 11.7245L15.2398 10.7891L11.5 14.1633L7.76023 10.7891L7 11.7245L11.12 15.4407L11.4999 15.7891Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.5"
                      />
                    </svg>
                  </div>
                  <div
                    className={`advanced-search-wrapper ${
                      Mactive ? "active" : ""
                    }`}
                  >
                    <div className="col-group">
                      <div className="col-dt-11">
                        <div className="selections">
                          {MwithoutDuplicates.map(item => {
                            const isSelected = MfilterCompleted.includes(item)
                            const classtopass = isSelected? "selected" : ""
                            return (
                              <a
                                href="javascript:void(0)"
                                className={`anchor ${classtopass && classtopass}`}
                                onClick={e => {
                                  handleFilterSelection(item, "MFilter")
                                  setCurrentPage(1)
                                  setactive(false)
                                }}
                              >
                                {item}
                              </a>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`advanced-btn ${Tactive ? "active" : ""}`}
                  onClick={() => {
                    setTactive(!Tactive)
                    setAactive(false)
                    setMactive(false)
                    setactive(false)
                  }}
                >
                  <div className="text">
                  {string_translation(dbdata.stringTranslations, "type", locale)}
                  </div>
                  <div className="drop-down-arrow">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12.7891" r="12" fill="#3D3935" />
                      <path
                        d="M11.4999 15.7891L11.88 15.4407L16 11.7245L15.2398 10.7891L11.5 14.1633L7.76023 10.7891L7 11.7245L11.12 15.4407L11.4999 15.7891Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.5"
                      />
                    </svg>
                  </div>
                  <div
                    className={`advanced-search-wrapper ${
                      Tactive ? "active" : ""
                    }`}
                  >
                    <div className="col-group">
                      <div className="col-dt-11">
                        <div className="selections">
                          {TwithoutDuplicates.map(item => {
                            const isSelected = TfilterCompleted.includes(item)
                            const classtopass = isSelected? "selected" : ""
                            return (
                              <a
                                href="javascript:void(0)"
                                className={`anchor ${classtopass && classtopass}`}
                                onClick={e => {
                                  handleFilterSelection(item, "TFilter")
                                  setCurrentPage(1)
                                  setactive(false)
                                }}
                              >
                                {item}
                              </a>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`advanced-btn ${Aactive ? "active" : ""}`}
                  onClick={() => {
                    setAactive(!Aactive)
                    setactive(false)
                    setMactive(false)
                    setTactive(false)
                  }}
                >
                  <div className="text">
                  {string_translation(dbdata.stringTranslations, "activity", locale)}
                  </div>
                  <div className="drop-down-arrow">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12.7891" r="12" fill="#3D3935" />
                      <path
                        d="M11.4999 15.7891L11.88 15.4407L16 11.7245L15.2398 10.7891L11.5 14.1633L7.76023 10.7891L7 11.7245L11.12 15.4407L11.4999 15.7891Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.5"
                      />
                    </svg>
                  </div>
                  <div
                    className={`advanced-search-wrapper ${
                      Aactive ? "active" : ""
                    }`}
                  >
                    <div className="col-group">
                      <div className="col-dt-11">
                        <div className="selections">
                          {AwithoutDuplicates.map(item => {
                            const isSelected = AfilterCompleted.includes(item)
                            const classtopass = isSelected? "selected" : ""
                            return (
                              <a
                                href="javascript:void(0)"
                                className={`anchor ${classtopass && classtopass}`}
                                onClick={e => {
                                  handleFilterSelection(item, "AFilter")
                                  setCurrentPage(1)
                                  setactive(false)

                                }}
                              >
                                {item}
                              </a>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button className="search-btn" onClick={handleclick}>
                  {string_translation(dbdata.stringTranslations, "clear", locale)}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="last-updates-posts">
          {todosData.length === 0 ? <><div className="container"><p className="no-events">{string_translation(dbdata.stringTranslations, "no", locale)}
</p></div></> : <>{todosData.map((item, index) => {
            return (
              <div className="events-wrapper" key={"n" + index}>
                <div className="container custom-container desktop-show">
                  <div
                    className={
                      "last-happenings-wrapper " + item.eventOptions.color
                    }
                  >
                    <div className="last-happenings-wrapper2">
                      <div className="col-group">
                        <div className="col-dt-6 col-left">
                          <div className="event-type">
                            {item?.eventTypes?.nodes[0]?.name}
                          </div>
                          <div className="event-title">{item?.title}</div>
                          <div className="event-date-wrapper">
                            <div className="event-start-calendar">
                              <img
                                src="../../images/last-happenings/calendar.svg"
                                alt="Calender"
                              />
                            </div>
                            <div className="event-start-date">
                              {item.eventOptions.eventDateFrom}
                            </div>
                            {item.eventOptions.eventDateTo != null ? (
                              <>
                                <div className="event-end-to">{string_translation(dbdata.stringTranslations, "to", locale)}</div>
                                <div className="event-end-date">
                                  {item.eventOptions.eventDateTo}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="locator-wrapper">
                            <div className="locator-logo">
                              <img
                                src="../../images/last-happenings/locaor-logo.svg"
                                alt="Locaor"
                              />
                            </div>
                            <div
                              className="locator-text"
                              dangerouslySetInnerHTML={createMarkup(
                                item.excerpt
                              )}
                            ></div>
                          </div>
                          <div className="learn-more-wrapper">
                            <div className="learn-more">
                              <Link
                                className="learn-more-btn"
                                to={item?.uri}
                                state={{ parenturl: originalUrl }}
                              >
                                {" "}
                                {item?.eventOptions?.buttonName}
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-dt-6">
                          <div className="event-img">
                            <img
                              src={
                                item?.previewImage?.previewImage?.localFile?.publicURL
                              }
                              alt={item?.title}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="last-happening-mobile-big-wrapper">
                  <div className="mobile-show last-happening-mobile">
                    <div className="container">
                      <div
                        className={
                          "last-happening-event-img " +
                          item.eventOptions.color
                        }
                      >
                        <img
                          src={
                            item?.previewImage?.previewImage?.localFile?.publicURL
                          }
                          alt={item?.title}
                        />
                      </div>
                      <div className="col-dt-6 col-left">
                        <div className="event-type">
                          {item?.eventTypes?.nodes[0]?.name}
                        </div>
                        <div className="event-title">{item?.title}</div>
                        <div className="event-date-wrapper">
                          <div className="event-start-calendar">
                            <img
                              src="../../images/last-happenings/calendar.svg"
                              alt="Calender"
                            />
                          </div>
                          <div className="event-start-date">
                            {item.eventOptions.eventDateFrom}
                          </div>
                          {item.eventOptions.eventDateTo != null ? (
                            <>
                              <div className="event-end-to">{string_translation(dbdata.stringTranslations, "to", locale)}</div>
                              <div className="event-end-date">
                                {item.eventOptions.eventDateTo}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="locator-wrapper">
                          <div className="locator-logo">
                            <img
                              src="../../images/last-happenings/locaor-logo.svg"
                              alt="Locaor"
                            />
                          </div>
                          <div
                            className="locator-text"
                            dangerouslySetInnerHTML={createMarkup(
                              item.excerpt
                            )}
                          ></div>
                        </div>
                        <div className="learn-more-wrapper">
                          <div className="learn-more">
                            <Link
                              className="learn-more-btn"
                              to={item?.uri}
                              state={{ parenturl: originalUrl }}
                            >
                              {" "}
                              {item?.eventOptions?.buttonName}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
          {pag ? (
            <div className="grid-pagination">
              <ul className="custom-pagination">
                <li>
                  <a onClick={() => {
                    onPrevious()
                    scrollToTop()
                  }}>
                    <svg
                      width="6"
                      height="9"
                      viewBox="0 0 6 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.06495 4.98826C5.16042 4.89291 5.21411 4.76355 5.21423 4.62862V4.37076C5.21267 4.23612 5.15919 4.10729 5.06495 4.01112L1.57709 0.530048C1.51338 0.465817 1.42666 0.429688 1.3362 0.429688C1.24573 0.429688 1.15901 0.465817 1.0953 0.530048L0.613519 1.01183C0.54969 1.07437 0.51372 1.15997 0.51372 1.24933C0.51372 1.33869 0.54969 1.42429 0.613519 1.48683L3.63316 4.49969L0.613519 7.51255C0.549288 7.57625 0.513159 7.66297 0.513159 7.75344C0.513159 7.84391 0.549288 7.93063 0.613519 7.99433L1.0953 8.46933C1.15901 8.53356 1.24573 8.56969 1.3362 8.56969C1.42666 8.56969 1.51338 8.53356 1.57709 8.46933L5.06495 4.98826Z"
                        fill="#3D3935"
                        stroke="#3D3935"
                        stroke-width="0.5"
                      ></path>
                    </svg>
                  </a>
                </li>
                {pageNumbers.map(number => (
                  <li
                    key={"t" + number}
                    className={`page-item ${
                      currentPage === number ? "active" : ""
                    }`}
                  >
                    <a
                      href="javascript:void(0)"
                      className="pagination "
                      onClick={() => {
                        paginate(number)
                        scrollToTop()
                      }}
                    >
                      {number}
                    </a>
                  </li>
                ))}
                <li>
                  <a onClick={() => {
                    onNext()
                    scrollToTop()
                  }}>
                    <svg
                      width="6"
                      height="9"
                      viewBox="0 0 6 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.06495 4.98826C5.16042 4.89291 5.21411 4.76355 5.21423 4.62862V4.37076C5.21267 4.23612 5.15919 4.10729 5.06495 4.01112L1.57709 0.530048C1.51338 0.465817 1.42666 0.429688 1.3362 0.429688C1.24573 0.429688 1.15901 0.465817 1.0953 0.530048L0.613519 1.01183C0.54969 1.07437 0.51372 1.15997 0.51372 1.24933C0.51372 1.33869 0.54969 1.42429 0.613519 1.48683L3.63316 4.49969L0.613519 7.51255C0.549288 7.57625 0.513159 7.66297 0.513159 7.75344C0.513159 7.84391 0.549288 7.93063 0.613519 7.99433L1.0953 8.46933C1.15901 8.53356 1.24573 8.56969 1.3362 8.56969C1.42666 8.56969 1.51338 8.53356 1.57709 8.46933L5.06495 4.98826Z"
                        fill="#3D3935"
                        stroke="#3D3935"
                        stroke-width="0.5"
                      ></path>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          ) : (
            <div></div>
          )}</>}

        </div>
      </section>
      </div>
    </>
  )
}

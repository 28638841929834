import React,{useState, Component} from "react"
import ReactModal from 'react-modal'
import "./videobanner.scss"
// import Swiper core and required modules
import SwiperCore, {Autoplay, Lazy } from "swiper"

import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"


export default function VideoBanner(props) {
  SwiperCore.use([Autoplay, Lazy])

  const { data, slider } = props
  const swiperRef = React.useRef(null)

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  // const shouldRenderArrows = slider.length > 1

  const [isOpen, setOpen] = useState(false)

  const handleModalClose = event => {
    setOpen(false)
  }

  ReactModal.setAppElement('#___gatsby')
  let showModal = true

  return (
    <div className="video-banner">
      <section className="first-block">
        <div className="wrapper">
          <div className="img-wrapper">
            <video autoPlay playsInline muted loop>
              <source
                src={data?.eventVideo?.localFile?.publicURL}
                type="video/mp4"
              />
            </video>
            {showModal && data?.videoLink ? (
              <div>
                <ReactModal
                  isOpen={isOpen}
                  onRequestClose={handleModalClose}
                >
                  <div className="modal-video-movie-wrap">
                    <button class="modal-video-close-btn"  onClick={handleModalClose}></button>
                    <iframe width="460" height="230" src={"https://www.youtube.com/embed/"+data?.videoLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </ReactModal>
                
                <div className="play-btn" onClick={() => setOpen(true)}>
                  <img src="/images/play-btn.svg" alt="Play" />
                </div>
                <div className="spinner">
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </section>
    </div>
  )
}
